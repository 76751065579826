import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import SingleMfnItem from "../components/SingleMfnItem"
export default function PostTemplate({ data }) {
  console.log(data)

  return (
    <Layout>
      <Seo title={data.wpMfnItem.title} />
      <SingleMfnItem data={data} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String = "") {
    wpMfnItem(id: { eq: $id }) {
      id
      title
      content
      date(formatString: "YYYY-MM-DD")
    }
  }
`
