import React from "react"

export default function SingleMfnItem({ data }) {
  const { title, content, date } = data.wpMfnItem
  return (
    <div className="max-w-2xl mx-auto py-8 md:py-32 space-y-8 px-4 md:px-0">
      <header className="space-y-4">
        <h1 className="text-4xl text-brand font-bold">{title}</h1>
        <date className="block">{date}</date>
      </header>
      <article>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="space-y-4"
        />
      </article>
    </div>
  )
}
